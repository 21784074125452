<template>         
    <v-sheet 
        class="main-view pa-12"
        width="100%"  
        min-height="100%"
        style="background-color: white;">
            <div class="text-h4 font-weight-bold primary--text pb-6">Welcome, {{ employee.name }}</div>
            <div class="bp-12">
                <div class="text-h6 primary--text">Course List</div>
                <v-row>
                    <v-col
                        v-for="course in courses"
                        :key="course.id"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3">
                            <course-swatch :course="course" @click="showCourseOverview(course)"/>
                    </v-col>
                </v-row>
                <v-dialog
                    v-model="isShowingCourseOverviewDialog"
                    max-width="800">
                        <course-overview :course="shownCourseForOverview"/>
                </v-dialog>
            </div>
    </v-sheet>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';

    import CourseSwatch from '@/components/CourseSwatch.vue';
    import CourseOverview from '@/components/CourseOverview.vue';

    export default {
        name: 'Home',
        components: {
            CourseSwatch,
            CourseOverview,
        },
        data() {
            return {
                isShowingCourseOverviewDialog: false,
                shownCourseForOverview: null,
            };
        },
        computed: {
            ...mapState([
                'employee',
            ]),
            ...mapGetters([
                'courses',
            ]),
        },
        methods: {
            showCourseOverview(course) {
                this.shownCourseForOverview = course;

                this.isShowingCourseOverviewDialog = true;
            }
        }
    };
</script>